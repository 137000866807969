import { useAtom, useAtomValue } from 'jotai'
import { applicantInfoAtom, userIdAtom, userTokenAtom, userTypeAtom } from '../atoms'
import { formatForDb, reformatFromDB } from '../utils/formatForDb'
import { devlog } from '../utils'
import { getLockrocketSessionUrl } from '../utils/logrocket.utils'
const workerUrl = process.env.NEXT_PUBLIC_WORKER

interface QueryUserResponseSuccess {
  success: true
  userInfo: UserInfo
  error: null
}
interface QueryUserResponseFailure {
  success: false
  error: string
  userInfo: null
}
export type QueryUserResponse = QueryUserResponseSuccess | QueryUserResponseFailure

type Stats = {
  applying: number
  submitted: number
  approved: number
  rejected: number
}
export interface ReferralSummaryData {
  message: string
  summary?: Stats
  referralAmountPerApp: number
  referralRateType: 'flat' | 'percent'
  downlineRateType: 'flat' | 'percent'
  disableApplicant: boolean
  enableSubAffiliate: boolean
  referralUrl: string
  downlineRefUrl?: string
  downlineRate?: number | null
  downline: {
    [k: string]: Stats
  }
  terms_accepted?: boolean | null
}

export interface ReferralListData {
  message: string
  referrals: Array<{ name: string; status: string; customer_id?: string; source_tracking_id?: string }>
}

interface QueryUserTaskResponseSuccess {
  success: true
  tasks: DBApplicantTask[]
  error: null
}
interface QueryUserTaskResponseFailure {
  success: false
  error: string
  tasks: null
}
export type QueryUserTaskResponse = QueryUserTaskResponseSuccess | QueryUserTaskResponseFailure

export type QueryPreClaimedCode = {
  success: boolean
  claimed: boolean | null
  error: string | null
}

interface UseQueryUserReturn {
  getUser: (user_token?: string) => Promise<QueryUserResponse>
  getUserReferralSummary: (user_token?: string) => Promise<QueryResponse<ReferralSummaryData>>
  getUserReferralList: (user_token?: string) => Promise<QueryResponse<ReferralListData>>
  postUser: (user: UserInfo | {}) => Promise<QueryUserResponse>
  getReferrer: (referrerCode: string) => Promise<QueryResponse<ReferrerData>>
  addAffiliate: (AffiliateInfo: AffiliateData & { referrerCode?: string }) => Promise<QueryResponse<AffiliateData>>
  checkPreClaimedCode: (code: string) => Promise<QueryPreClaimedCode>
  isReady: boolean
}

const useQueryUser = (): UseQueryUserReturn => {
  const [userId, setUserId] = useAtom(userIdAtom)
  const [userType, setUserType] = useAtom(userTypeAtom)
  const [userToken, setUserToken] = useAtom(userTokenAtom)
  const applicantInfo = useAtomValue(applicantInfoAtom)

  const updateIfNecessary = ({ id, token, force }: { id?: string | null; token?: string | null; force?: boolean }) => {
    if (id && (userId !== id || force)) {
      setUserId(id)
    }
    if (token && (userToken !== token || force)) {
      setUserToken(token)
    }
  }

  const getUser: UseQueryUserReturn['getUser'] = async (user_token?: string) => {
    if (userType === 'affiliate') {
      return { success: false, userInfo: null, error: 'cannot request applicant info for affiliate only user' }
    }
    if (!userToken && !user_token) {
      return { success: false, userInfo: null, error: 'cannot request user info when userToken is unset' }
    }
    let queryUrl = `${workerUrl}/applicant`
    try {
      const response = await fetch(queryUrl, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${user_token ?? userToken}`,
        },
      })
      const { applicant, message, token }: ApplicantResponseData = await response?.json?.()
      if (response.ok && applicant) {
        const userInfo = reformatFromDB(applicant)
        if (userInfo.id) updateIfNecessary({ id: userInfo.id, token })
        devlog('useQuery >> getUser', applicant)
        return { success: true, error: null, userInfo }
      }
      if (message === 'Invalid Token') {
        return { success: false, userInfo: null, error: 'Invalid Token' }
      }
      devlog(
        'useQueryUser >> getUser NOT response.ok',
        JSON.stringify({ response, applicant, message, userToken, user_token }, null, 2)
      )
      return { success: false, userInfo: null, error: typeof message === 'string' ? message : 'NOT response.ok' }
    } catch (e) {
      return { success: false, userInfo: null, error: JSON.stringify(e) }
    }
  }

  const getUserReferralSummary: UseQueryUserReturn['getUserReferralSummary'] = async (user_token?: string) => {
    if (!userToken && !user_token) {
      return { success: false, body: null, error: 'cannot request user dashboard when userToken is unset' }
    }
    let queryUrl = `${workerUrl}/referral`
    try {
      const response = await fetch(queryUrl, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${user_token ?? userToken}`,
        },
      })
      const data = await response?.json?.()
      if (!!data) {
        devlog('useQuery >> getUserReferralSummary')
        return { success: true, error: null, body: data }
      }

      devlog(
        'useQuery >> getUserReferralSummary NOT response.ok',
        JSON.stringify({ response, userToken, user_token }, null, 2)
      )
      return { success: false, body: null, error: 'NOT response.ok' }
    } catch (e) {
      return { success: false, body: null, error: JSON.stringify(e) }
    }
  }

  const getUserReferralList: UseQueryUserReturn['getUserReferralList'] = async (user_token?: string) => {
    if (!userToken && !user_token) {
      return { success: false, body: null, error: 'cannot request user dashboard when userToken is unset' }
    }
    let queryUrl = `${workerUrl}/referral/list`
    try {
      const response = await fetch(queryUrl, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${user_token ?? userToken}`,
        },
      })
      const data = await response?.json?.()
      if (!!data) {
        devlog('useQuery >> getUserReferralSummary')
        return { success: true, error: null, body: data }
      }

      devlog(
        'useQuery >> getUserReferralSummary NOT response.ok',
        JSON.stringify({ response, userToken, user_token }, null, 2)
      )
      return { success: false, body: null, error: 'NOT response.ok' }
    } catch (e) {
      return { success: false, body: null, error: JSON.stringify(e) }
    }
  }

  const getReferrer: UseQueryUserReturn['getReferrer'] = async (referrerCode) => {
    let results: BaseResponse = { success: false, body: null, error: null }
    let queryUrl = `${workerUrl}/referral/?referrerCode=${referrerCode}`
    try {
      const response = await fetch(queryUrl, {
        headers: {
          Accept: 'application/json',
        },
      })
      const data = await response?.json?.()
      if (!!data && response.ok) {
        devlog('useQuery >> getReferrer')
        results.success = true
        results.body = data
        return results as QueryResponseSuccess<ReferrerData>
      }
      devlog('useQuery >> getReferrer NOT response.ok', JSON.stringify({ response, referrerCode }))
      results.error = 'NOT response.ok'
    } catch (e) {
      results.error = JSON.stringify(e)
    }
    return results as QueryResponseError
  }

  const postUser: UseQueryUserReturn['postUser'] = async (update) => {
    const fromAffiliate = Object.getOwnPropertyNames(update).length === 0
    if (!userToken && !('email' in update && !!update.email)) {
      return { success: false, userInfo: null, error: 'cannot update an existing user when userToken is unset' }
    }
    let submitUrl = `${workerUrl}/applicant`
    const formattedForDB = JSON.stringify({
      ...formatForDb({ ...update }),
      customer_id: applicantInfo.customer_id,
      source_tracking_id: applicantInfo.source_tracking_id,
    })

    let sessionUrl = getLockrocketSessionUrl()

    try {
      const response = await fetch(submitUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'x-logrocket-url': sessionUrl,
          ...(userToken ? { Authorization: `Bearer ${userToken}` } : {}),
        },
        body: formattedForDB,
      })
      const { applicant, message, token }: ApplicantResponseData = await response?.json?.()
      if (response.ok && applicant) {
        // Form submission was successful
        devlog('useQueryUser >> postUser', { update, response, applicant })
        const userInfo = reformatFromDB(applicant)
        updateIfNecessary({ id: userInfo.id, token })
        if (fromAffiliate) {
          devlog('useQueryUser >> postUser, setting usertype to both')
          setUserType('both')
        }
        return { success: true, error: null, userInfo }
      } else {
        devlog(
          'useQueryUser >> updateUser NOT response.ok, or applicant data missing',
          JSON.stringify({ formattedForDB, applicant, message }, null, 2)
        )
        return { success: false, userInfo: null, error: typeof message === 'string' ? message : 'NOT response.ok' }
      }
    } catch (error) {
      return { success: false, userInfo: null, error: JSON.stringify(error) }
    }
  }

  const addAffiliate: UseQueryUserReturn['addAffiliate'] = async (newAffiliate) => {
    let results: BaseResponse = { success: false, body: null, error: null }
    if (userType === 'applicant') {
      newAffiliate.applicant_id = userId
    }
    let submitUrl = `${workerUrl}/affiliate`
    // let sessionUrl = getLockrocketSessionUrl()

    try {
      const response = await fetch(submitUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          // 'x-logrocket-url': sessionUrl,
          ...(userToken ? { Authorization: `Bearer ${userToken}` } : {}),
        },
        body: JSON.stringify(newAffiliate),
      })
      const { affiliate, message, token }: AffiliateResponseData = await response?.json?.()
      if (response.ok && affiliate) {
        devlog('useQueryUser >> addAffiliate', { affiliate, response, newAffiliate })
        updateIfNecessary({ id: affiliate.id, token })
        if (affiliate.applicant_id) {
          devlog('useQueryUser >> addAffiliate, setting usertype to both')
          setUserType('both')
        } else {
          setUserType('affiliate')
        }
        results.success = true
        results.body = affiliate
      }
      devlog('useQuery >> addAffiliate NOT response.ok', JSON.stringify({ response, newAffiliate }))
      results.error = typeof message === 'string' ? message : 'NOT response.ok'
    } catch (e) {
      results.error = JSON.stringify(e)
    }
    return results as QueryResponseError
  }

  const checkPreClaimedCode: UseQueryUserReturn['checkPreClaimedCode'] = async (code) => {
    let results: QueryPreClaimedCode = { success: false, claimed: null, error: null }
    // return { success: true, claimed: true, error: null }
    // @todo replace witht he right endpoint, used line above for testing
    let submitUrl = `${workerUrl}/pre_created_code/${code}`
    // let sessionUrl = getLockrocketSessionUrl()
    try {
      const response = await fetch(submitUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          // 'x-logrocket-url': sessionUrl,
        }
      })
      const { exists, message } = await response?.json?.()
      if (message !== 'ok') {
        throw 'Invalid Response'
      }
      devlog('useQueryUser >> checkPreclaimedCode', { claimed: exists, response })
      results.success = true
      results.claimed = exists
    } catch (e) {
      results.error = JSON.stringify(e)
    }
    return results
  }

  return {
    getUser,
    postUser,
    isReady: !!userToken,
    getUserReferralSummary,
    getUserReferralList,
    getReferrer,
    addAffiliate,
    checkPreClaimedCode,
  }
}

export default useQueryUser
