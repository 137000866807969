import { Control, SetFieldValue, UseControllerProps } from 'react-hook-form'
export enum Steps {
  //CMC Application
  'Prequalifier' = -2,
  'AccountInfo' = -1,
  'FinancialInfo' = 0,
  'EnterDates' = 1,
  'TaxInfo' = 2,
  'ReadyForCPA' = 20,
  'SubmittedToCPA' = 21,
  //CPA Prequalification
  'NewSubmission' = 22, //Confirmation from CPA they received it, Can go to 24, 25, 27
  'Disqualified' = 24,
  'WaitingForDocs' = 25,
  'DocumentsInReview' = 26,
  'Prequalified' = 27, //We need get them to pay
  'Paid' = 29,
  //CPA Filing
  'FilingNotStarted' = 30,
  'FilingStarted' = 35,
  'FilingAnxiouslyWaiting' = 34,
  'FilingReadyForReview' = 37,
  'FilingWaitingOn_8879' = 38,
  'FilingSigned' = 39,
  'Efiled' = 40,
  //IRS
  'IRSCompleted' = 45,
  'IRSRejected' = 44,
  'IRSCreditIssued' = 50,
}

export enum ReferralStatus {
  'Applying' = 0,
  'Submitted' = 1,
  'Rejected' = 2,
  'Approved' = 3,
}
export type Referral = {
  name: string
  status: keyof typeof ReferralStatus
}
export type Affiliate = {
  name: string
  applying: number
  submitted: number
  approved: number
  rejected: number
}
export type FormInputProps = {
  control: Control<any>
  name: string
  label?: string
  setValue: SetFieldValue<any>
  helpText?: string
  rules?: UseControllerProps['rules']
  autoComplete?: string
}

export interface UserFormProps {
  onBack?: (userInfo?: UserInfo) => void
  onContinue: (userInfo?: UserInfo) => void
  setErrorState?: (error: string | null) => void
  isPosting?: boolean
}

export type PrequalifierData = {
  selfEmployed: boolean
  positiveNet: boolean
  missedTime: boolean
}

export type RegisterData = {
  firstName: string
  lastName: string
  email: string
  termsAndConditions: boolean
}

export type FinancialInfoData = {
  incomeGreater2019: boolean
  netIncome2019?: number
  netIncome2020?: number
  netIncome2021?: number
  netIncome2019Range: number | null
  netIncome2020Range: number | null
  netIncome2021Range: number | null
}

export type DatesData = {
  covidDates2020?: Array<CovidDateRange>
  covidDates2021?: Array<CovidDateRange>
}

export enum DateSteps {
  dateIntro = -1,
  datesForYourself = 0,
  datesForAnother = 1,
  datesForChild = 2,
  creditSummary = 3,
}

export interface DatesPageProps {
  covidDates2020: Array<CovidDateRange>
  covidDates2021: Array<CovidDateRange>
  updateDates: (args: {
    dates2020?: Array<CovidDateRange>
    dates2021?: Array<CovidDateRange>
    reason: CovidDateRange['reason']
  }) => void
}

export type Info2020Data = {
  incomeGreater2019: boolean
  w2_2020: boolean
  netIncome2019?: number
  netIncome2020?: number
  covidDates2020?: Array<CovidDateRange>
  covidDates2021?: Array<CovidDateRange>
  leavePay2020: number
}

export type Info2021Data = {
  incomeGreater2020: boolean
  w2_2021: boolean
  netIncome2020?: number
  netIncome2021?: number
  covidDates2021?: Array<CovidDateRange>
  leavePay2021: number
}

export type PersonalInfoData = {
  dateOfBirth: string
  nameOfCompany?: string
  industry: string
  street: string
  street2?: string
  city: string
  state: string
  zip: string
}

export type AddressData = {
  street: string
  street2?: string
  city: string
  state: string
  zip: string
}
