export const formatForDb = (userInfo: UserInfo, referrerCode?: string): Partial<DBUser> => {
  const userInfoForDB = {
    id: userInfo.id ?? userInfo.id,
    first_name: userInfo.firstName,
    last_name: userInfo.lastName,
    email: userInfo.email,
    phone: userInfo.phone,
    terms_accepted: userInfo.termsAndConditions,
    net_earnings_2019: userInfo.netIncome2019,
    net_earnings_2020: userInfo.netIncome2020,
    net_earnings_2021: userInfo.netIncome2021,
    covid_dates_2020: userInfo.covidDates2020 ? JSON.stringify(userInfo.covidDates2020) : undefined,
    covid_dates_2021: userInfo.covidDates2021 ? JSON.stringify(userInfo.covidDates2021) : undefined,
    business_name: userInfo.nameOfCompany,
    trade_name: userInfo.industry,
    dob: userInfo.dateOfBirth,
    street: userInfo.street,
    street2: userInfo.street2,
    city: userInfo.city,
    state: userInfo.state,
    zip: userInfo.zip,
    referrerCode: userInfo.referrerCode ?? referrerCode,
    step: userInfo.step,
  }
  for (const key in userInfoForDB) {
    if (Object.prototype.hasOwnProperty.call(userInfoForDB, key)) {
      const value = userInfoForDB[key as keyof typeof userInfoForDB]
      if (value === null || value === undefined) {
        delete userInfoForDB[key as keyof typeof userInfoForDB]
      }
    }
  }
  return userInfoForDB as Partial<DBUser>
}

export const reformatFromDB = (dbUser: DBUser): UserInfo => {
  try {
    const userFromDB = {
      id: dbUser.id ?? undefined,
      firstName: dbUser.first_name ?? undefined,
      lastName: dbUser.last_name ?? undefined,
      email: dbUser.email ?? undefined,
      phone: dbUser.phone ?? undefined,
      termsAndConditions: dbUser.terms_accepted ?? undefined,
      netIncome2019: dbUser.net_earnings_2019 ?? undefined,
      netIncome2020: dbUser.net_earnings_2020 ?? undefined,
      netIncome2021: dbUser.net_earnings_2021 ?? undefined,
      covidDates2020: dbUser.covid_dates_2020
        ? typeof dbUser.covid_dates_2020 === 'string'
          ? JSON.parse(dbUser.covid_dates_2020)
          : Array.isArray(dbUser.covid_dates_2020)
            ? dbUser.covid_dates_2020
            : []
        : [],
      covidDates2021: dbUser.covid_dates_2021
        ? typeof dbUser.covid_dates_2021 === 'string'
          ? JSON.parse(dbUser.covid_dates_2021)
          : Array.isArray(dbUser.covid_dates_2021)
            ? dbUser.covid_dates_2021
            : []
        : [],
      nameOfCompany: dbUser.business_name ?? undefined,
      industry: dbUser.trade_name ?? undefined,
      dateOfBirth: dbUser.dob ?? undefined,
      street: dbUser.street ?? undefined,
      street2: dbUser.street2 ?? undefined,
      city: dbUser.city ?? undefined,
      state: dbUser.state ?? undefined,
      zip: dbUser.zip ?? undefined,
      referralCode: dbUser.referral_code ?? undefined,
      step: dbUser.step ?? undefined,
      allDiscountTotal: dbUser.all_discount_total ?? 0,
      discountedProcessingFee: dbUser.discounted_processing_fee ?? 0,
      fullFilingFee: dbUser.full_filing_fee ?? 0,
      processingDiscountOnlyTotal: dbUser.processing_discount_only_total ?? 0,
      noDiscountTotalFee: dbUser.no_discount_total_fee ?? 0,
      informationNeeded: dbUser.information_needed ?? undefined,
      applicantReferrerId: dbUser.applicant_referrer_id ?? undefined,
      affiliateReferrerId: dbUser.affiliate_referrer_id ?? undefined,
      estimatedCredit: dbUser.estimated_credit ?? undefined,
      credit2020: dbUser.credit_2020 ?? undefined,
      credit2021: dbUser.credit_2021 ?? undefined,
    }
    return userFromDB
  } catch (e) {
    console.error('error reformatting user from db format', dbUser)
  }
  return {}
}
