import { atom } from 'jotai'
import { atomWithReducer, atomWithStorage, createJSONStorage } from 'jotai/utils'
import { creditCalculator, devlog } from './utils'
import LogRocket from 'logrocket'
import { DateSteps } from './steps/FormTypes'

export const userInfoReducerAtom = atomWithReducer<UserInfo, UserInfo & { shouldClear?: boolean }>(
  {},
  (existing, update) => {
    if (update?.shouldClear) {
      devlog('Wiping stored userInfo')
      return {}
    } else {
      devlog('Merging existing user info and update', { existing, update })
      if (!existing.id && update?.id) {
        devlog('Setting LogRocket user id to', update.id)
        LogRocket.identify(update.id, {
          name: update.firstName + ' ' + update.lastName,
          email: update.email ?? '',
          phone: update.phone ?? '',
        })
      }
    }

    return { ...existing, ...update }
  }
)

export const income2019Atom = atom(0)
export const income2020Atom = atom(0)
export const income2021Atom = atom(0)

export const covidDates2020Atom = atom<CovidDateRange[]>([])
export const covidDates2021Atom = atom<CovidDateRange[]>([])

export const covidDates2020ForEstimate: Array<CovidDateRange> = [
  { start: '2021-04-01', end: '2021-04-06', reason: 'PERSONAL', days: 10 },
  { start: '2021-04-01', end: '2021-04-30', reason: 'CHILD', days: 50 },
]
export const covidDates2021ForEstimate: Array<CovidDateRange> = [
  { start: '2020-03-01', end: '2020-03-06', reason: 'PERSONAL', days: 10 },
  { start: '2020-03-01', end: '2020-03-30', reason: 'CHILD', days: 60 },
]

export const summaryCreditEstimateAtom = atom((get) => {
  const netIncome2019 = get(income2019Atom)
  const netIncome2020 = get(income2020Atom)
  const netIncome2021 = get(income2021Atom)

  const userInfo = get(userInfoReducerAtom)

  const { estimate2020, estimate2021, unusedDays, ...rest } = creditCalculator({
    netIncome2019: netIncome2019 ?? userInfo?.netIncome2019 ?? 0,
    netIncome2020: netIncome2020 ?? userInfo?.netIncome2020 ?? 0,
    netIncome2021: netIncome2021 ?? userInfo?.netIncome2021 ?? 0,
    covidDates2020: [{ start: '2021-04-01', end: '2021-04-02', reason: 'PERSONAL', days: 1 }],
    covidDates2021: [{ start: '2021-04-01', end: '2021-04-02', reason: 'PERSONAL', days: 1 }],
  })

  const { estimate2020: estimate2020Upper, estimate2021: estimate2021Upper, ...rest2 } = creditCalculator({
    ...userInfo,
    netIncome2019: netIncome2019 ? netIncome2019 + 10000 : netIncome2019,
    netIncome2020: netIncome2020 ? netIncome2020 + 10000 : netIncome2020,
    netIncome2021: netIncome2021 ? netIncome2021 + 10000 : netIncome2021,
    covidDates2020: covidDates2020ForEstimate,
    covidDates2021: covidDates2021ForEstimate,
  })

  const _total = parseInt(`${estimate2020}`) + parseInt(`${estimate2021}`)
  const _totalUpper = parseInt(`${estimate2020Upper}`) + parseInt(`${estimate2021Upper}`)

  return {
    total: isNaN(_total) ? 0 : _total,
    estimate2020,
    estimate2021,
    unusedDays,
    totalUpper: _totalUpper,
    estimate2020Upper: estimate2020Upper,
    estimate2021Upper: estimate2021Upper,
    breakdown: rest,
    breakdownUpper: rest2,
    guesstimate: null
  }
})

export const summaryCreditAtom = atom((get) => {
  const dateStep = get(dateStepAtom)
  const userInfo = get(userInfoReducerAtom)
  const guesstimate = get(summaryCreditEstimateAtom)

  if (!userInfo.covidDates2020?.length && !userInfo.covidDates2021?.length) {
    devlog(
      'ESTI ESTI ESTI ESTI',
      '2020 dates: ',
      userInfo.covidDates2020?.length,
      '2021 dates: ',
      userInfo.covidDates2021?.length
    )
    return guesstimate
  }
  devlog(
    'NON - ESTI',
    '2020 dates: ',
    userInfo.covidDates2020?.length,
    '2021 dates: ',
    userInfo.covidDates2021?.length
  )

  const netIncome2019 = get(income2019Atom)
  const netIncome2020 = get(income2020Atom)
  const netIncome2021 = get(income2021Atom)

  const covidDates2020 = get(covidDates2020Atom)
  const covidDates2021 = get(covidDates2021Atom)

  const { estimate2020, estimate2021, unusedDays, ...rest } = creditCalculator({
    netIncome2019: netIncome2019 ?? userInfo?.netIncome2019 ?? 0,
    netIncome2020: netIncome2020 ?? userInfo?.netIncome2020 ?? 0,
    netIncome2021: netIncome2021 ?? userInfo?.netIncome2021 ?? 0,
    covidDates2020: covidDates2020?.length ? covidDates2020 : !covidDates2021?.length ? covidDates2020ForEstimate : [],
    covidDates2021: covidDates2021?.length ? covidDates2021 : !covidDates2020?.length ? covidDates2021ForEstimate : [],
  })

  const {
    estimate2020: estimate2020Upper,
    estimate2021: estimate2021Upper,
    ...rest2
  } = creditCalculator({
    ...userInfo,
    netIncome2019: netIncome2019 ? netIncome2019 + 10000 : netIncome2019,
    netIncome2020: netIncome2020 ? netIncome2020 + 10000 : netIncome2020,
    netIncome2021: netIncome2021 ? netIncome2021 + 10000 : netIncome2021,
    covidDates2020: covidDates2020?.length ? covidDates2020 : !covidDates2021?.length ? covidDates2020ForEstimate : [],
    covidDates2021: covidDates2021?.length ? covidDates2021 : !covidDates2020?.length ? covidDates2021ForEstimate : [],
  })

  const _total = parseInt(`${estimate2020}`) + parseInt(`${estimate2021}`)
  const _totalUpper = parseInt(`${estimate2020Upper}`) + parseInt(`${estimate2021Upper}`)

  if (userInfo.step && userInfo.step > 22 && userInfo.estimatedCredit) {
    return {
      total: userInfo.estimatedCredit,
      estimate2020: userInfo.credit2020 ?? estimate2020,
      estimate2021: userInfo.credit2021 ?? estimate2021,
      unusedDays,
    }
  }

  return {
    unusedDays,
    total: isNaN(_total) ? 0 : _total,
    estimate2020,
    estimate2021,
    totalUpper: isNaN(_totalUpper) ? 0 : _totalUpper,
    estimate2020Upper,
    estimate2021Upper,
    breakdown: rest,
    breakdownUpper: rest2,
    guesstimate
  }
})

const storage = createJSONStorage<string>(() => ({
  getItem: (key: string) => localStorage.getItem(key),
  setItem: (key: string, newValue: string) => localStorage.setItem(key, newValue),
  removeItem: (key: string) => localStorage.removeItem(key),
}))

export const userIdAtom = atomWithStorage<string>('user_id', '', storage)
export const userTokenAtom = atomWithStorage<string>('user_token', '', storage)
export const userTypeAtom = atomWithStorage<UserType>('user_type', 'unknown')
export const boxTokenAtom = atom<string>('')
export const boxUserFolderIdAtom = atom<string>('')
export const dateStepAtom = atom<DateSteps>(DateSteps.dateIntro)

interface ApplicantInfo {
  customer_id?: string
  source_tracking_id?: string
  first_name?: string
  last_name?: string
  email?: string
  phone?: string
}
export const applicantInfoAtom = atomWithStorage<ApplicantInfo>(
  'applicantInfo',
  {},
  createJSONStorage<ApplicantInfo>(() => ({
    getItem: (key: string) => JSON.parse(localStorage.getItem(key) ?? '{}'),
    setItem: (key: string, newValue: ApplicantInfo | string) => localStorage.setItem(key, JSON.stringify(newValue)),
    removeItem: (key: string) => localStorage.removeItem(key),
  }))
)
