import LogRocket from 'logrocket'

export function getLockrocketSessionUrl() {
  let sessionUrl: string = ''
  LogRocket.getSessionURL((sessUrl) => {
    sessUrl = sessUrl
  })

  return sessionUrl
}
